import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'
import axios from 'axios'
import { loginInfo } from '@/services/auth'
import { getAccountOrg, getTranslation } from '@/utils/filters'

export function listStorages(params = {}) {
  return request({
    url: ENDPOINT.LIST_STORAGES,
    method: 'get',
    params
  }).then((res) => {
    return {
      ...res,
      result: res.result.map((r) => {
        const translation = getTranslation(r, params.locale)
        const name = translation ? translation.name || translation.title : (r.name || r.title)
        return {
          ...r,
          name: name
        }
      })
    }
  })
}

export function createStorage(data) {
  const formData = new FormData()
  formData.append('file', data.file)
  formData.append('object_id', data.objectId)
  formData.append('file_type', data.fileType)
  formData.append('object_type', data.objectType)
  formData.append('locale', data.locale)
  if (data.isAvatar) {
    formData.append('is_avatar', data.isAvatar)
  }
  return axios({
    url: ENDPOINT.STORAGE,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${loginInfo() ? loginInfo().access_token : ''}`,
      'X-Organization-Code': getAccountOrg() ? getAccountOrg().code : ''
    },
    data: formData
  })
}

export function createStorageVideo(data) {
  return axios({
    url: ENDPOINT.STORAGE,
    method: 'put',
    headers: {
      'Content-Type': 'text/octet-stream',
      Authorization: `Bearer ${loginInfo() ? loginInfo().access_token : ''}`,
      'X-Organization-Code': getAccountOrg() ? getAccountOrg().code : ''
    },
    data
  })
}

export function updateStorage(data) {
  return request({
    url: ENDPOINT.STORAGE,
    method: 'patch',
    data
  })
}

export function deleteStorages(params) {
  return request({
    url: ENDPOINT.STORAGE,
    method: 'delete',
    params
  })
}

export function getStorage(params) {
  return request({
    url: ENDPOINT.LIST_STORAGES,
    method: 'get',
    params
  })
}

export function sortStorages(data) {
  return request({
    url: ENDPOINT.CHANGE_ORDER,
    method: 'post',
    data
  })
}

export function uploadStorageUrl(data) {
  return request({
    url: ENDPOINT.STORAGE_URL,
    method: 'post',
    data
  })
}
